import React, { FC } from "react";
import { defineMessages, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import classnames from "classnames";
import ChatAvatar from "chat/components/common/ChatAvatar";
import { MessageConfig } from "chat/components/currentConversation/Message";
import {
  Button,
  DisplayName,
  Gift,
  Spinner,
  Typography,
} from "chat/imports/components";
import {
  ButtonSize,
  ButtonVariant,
  TYPOGRAPHY_TYPE,
} from "chat/imports/constants";
import { useBreakpointPrecise } from "chat/imports/hooks";
import { RootState, profilesCacheSelectors } from "chat/imports/state";
import { AccountInfo } from "chat/imports/types";
import { formatDisplayName } from "chat/imports/utils";
import { VoidCallback } from "chat/messageRequest/imports/types";
import { StoredMessage } from "chat/state/reducer";
import { getGroupMessageClassnames } from "chat/utils/groupMessageClassnames";
import { MessageDuration } from "./MessageDuration";
import styles from "./LockedMessageOverlay.scss";
import commonStyles from "./common.scss";

const lockedOverlayMessages = defineMessages({
  description: {
    id: "premium_message_shared_premium_content",
    defaultMessage: "Shared Premium Message",
  },
  unlock: {
    id: "chat.message.premium.locked.unlock",
    defaultMessage: "Gift to View",
  },
});

interface LockedMessageOverlayProps {
  accountInfo?: AccountInfo;
  conversationId: string;
  duration?: number;
  giftId: string;
  isLoading: boolean;
  message: StoredMessage;
  messageConfig: MessageConfig;
  onUnlock: VoidCallback;
}

export const LockedMessageOverlay: FC<LockedMessageOverlayProps> = ({
  accountInfo,
  isLoading,
  conversationId,
  duration,
  giftId,
  message,
  onUnlock,
  messageConfig,
}) => {
  const intl = useIntl();
  const breakpoint = useBreakpointPrecise();

  const basicProfile = useSelector((state: RootState) =>
    profilesCacheSelectors.getBasicProfile(state, message.from)
  );

  const {
    first_name: firstName,
    last_name: lastName,
    thumbnail_url: thumbnailUrl,
  } = accountInfo || {};

  return (
    <div
      className={classnames(
        styles.root,
        getGroupMessageClassnames(styles, messageConfig),
        styles[breakpoint]
      )}
    >
      {!!duration && <MessageDuration duration={duration} />}

      <ChatAvatar
        conversationId={conversationId}
        pictureUrl={basicProfile?.profileThumbnailUrl || thumbnailUrl}
        basicProfile={basicProfile}
        accountInfo={accountInfo}
        name={formatDisplayName({
          intl,
          basicProfile,
          firstName,
          lastName,
        })}
      />

      <div className={styles.description}>
        <Typography
          className={styles.descriptionText}
          type={TYPOGRAPHY_TYPE.PARAGRAPH2}
        >
          <DisplayName
            basicProfile={basicProfile}
            firstName={firstName}
            lastName={lastName}
          />
        </Typography>

        <Typography
          className={styles.descriptionText}
          type={TYPOGRAPHY_TYPE.PARAGRAPH2}
        >
          {intl.formatMessage(lockedOverlayMessages.description)}
        </Typography>
      </div>

      <Gift
        id={giftId}
        giftSendDisabled
        className={classnames(commonStyles.gift, commonStyles.cursorInitial)}
      />

      <Button
        data-testid="unlock-button"
        className={styles.button}
        size={ButtonSize.BIG_48}
        variant={ButtonVariant.PRIMARY_ACCENT}
        disabled={isLoading}
        onClick={onUnlock}
      >
        {isLoading ? (
          <Spinner size={22} color="inherit" className={styles.spinner} />
        ) : (
          intl.formatMessage(lockedOverlayMessages.unlock)
        )}
      </Button>
    </div>
  );
};
