import React, { FC, forwardRef, useCallback, useEffect, useRef } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { CanvasPresenter, MediaEngine, Player } from "@video/video-framework";
import { getTangoPlayerConfig } from "src/state/SOC/playback";
import { Nullable, VoidCallback } from "src/types/common";
import { useUnmount } from "src/utils/miniReactUse";
import { PlayerProps } from "./PlayerProps";

const mediaEngine = new MediaEngine();

class LWCPlayerAdapter {
  private muted: boolean = false;
  private player: Nullable<Player> = null;
  private presenter: Nullable<CanvasPresenter> = null;
  private url: string;

  constructor(url: string, muted: boolean) {
    this.url = url;
    this.muted = muted;
  }

  mute() {
    this.muted = true;
    this.player?.mute();
  }

  pause() {
    this.player?.close();
    this.player = null;
  }

  play(listeners?: Record<string, VoidCallback>) {
    if (this.player === null) {
      this.player = mediaEngine.play(this.url, { muted: this.muted });
      listeners?.play?.();
      if (this.presenter) {
        this.player.presenter = this.presenter;
      }
    }
  }

  setPresenter(presenter: CanvasPresenter) {
    this.presenter = presenter;
    if (this.player) {
      this.player.presenter = this.presenter;
    }
  }

  unmute() {
    this.muted = false;
    this.player?.unmute();
  }

  get src(): string {
    return this.url;
  }
}

const TangoPlayer: FC<PlayerProps> = ({
  forwardedRef,
  src,
  poster,
  muted,
  videoEventListeners,
  forceDisableHd,
  paused,
  ...rest
}) => {
  const tangoPlayerConfig = useSelector(getTangoPlayerConfig, shallowEqual);

  const player = useRef<Nullable<LWCPlayerAdapter>>(null);

  const createPlayer = useCallback(
    (src: string) => {
      if (player.current?.src === src || src.trim().length === 0) {
        return;
      }

      player.current = new LWCPlayerAdapter(src, false);
      const canvas = (forwardedRef as React.MutableRefObject<HTMLCanvasElement>)
        .current;
      canvas.getContext("2d")?.clearRect(0, 0, canvas.width, canvas.height);
      player.current.setPresenter(mediaEngine.createCanvasPresenter(canvas));
      mediaEngine.applyDebugOptions(tangoPlayerConfig);
    },
    [forwardedRef, tangoPlayerConfig]
  );

  useEffect(() => {
    if (player.current === null) {
      createPlayer(src);
    }
    muted ? player.current?.mute() : player.current?.unmute();
  }, [muted, src, createPlayer]);

  useEffect(() => {
    if (player.current === null) {
      createPlayer(src);
    }
    paused === false
      ? player.current?.play(videoEventListeners)
      : player.current?.pause();
  }, [videoEventListeners, paused, src, createPlayer]);

  useEffect(() => {
    player.current?.pause();
    createPlayer(src);
    player.current?.play(videoEventListeners);
  }, [src, videoEventListeners, createPlayer]);

  useUnmount(() => {
    player.current?.pause();
    player.current = null;
  });

  return <canvas ref={forwardedRef} width={720} height={1280} {...rest} />;
};

const ref = forwardRef<HTMLCanvasElement, PlayerProps>((props, ref) => (
  <TangoPlayer {...props} forwardedRef={ref} />
));
ref.displayName = "TangoPlayer";

export default ref;
